import axios from 'axios'
import $api from "@/helpers/config-axios";
import authHeader from "@/services/auth-header";
import store from "@/store";

class EstimatesService {
  getEstimates(nextPage) {
    const {orderBy, sortedBy, search, paginationPage, isArchive, filter, status} = store.state.estimates
    let params = {
      page: nextPage,
      include: 'status,pdf_export,createdBy',
      // search: {
      //   title: search,
      //   status_id: status,
      // },
      orderBy: orderBy,
      sortedBy: sortedBy,
      searchJoin: 'and'
    }

    if (filter !== null) params['search'] = filter.main
    if (search !== '') params['find'] = search

    if (isArchive) params['only-trashed'] = `1`

    return $api.get(
      `estimates`,
      {
        params: params,
        headers: authHeader(),
      }
    )
  }

  getStatusesEstimates() {
    return $api.get(
      `estimate/statuses?to=list`,
      {
        headers: authHeader(),
      }
    )
  }

  addEstimate() {
    return $api.post(`estimates`, {}, {headers: authHeader()})
  }

  copyEstimate(id) {
    return $api.post(`estimates/${id}`, {}, {headers: authHeader()})
  }

  deleteEstimate(ids) {
    return axios.get(`${process.env.VUE_APP_GOOGLE_DRIVE}remove-item.php?ids=${ids}`)
  }

  deleteEstimates(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api.delete('estimates', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.estimates.selectedItemsForDelete,
      },
    })
  }

  archiveItems() {
    return $api.post(
      'restore-estimates',
      {
        ids: store.state.estimates.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }
}

export default new EstimatesService()
