<template>
  <div class="details-form">
    <div class="details-form__header">
      <h4>Новая смета</h4>

      <!-- Создание заявки -->
      <div class="details-form__header-block">
        <zem-link @click="onCancelCreation">{{ $t('Cancel') }}</zem-link>
        <zem-button :loading="loadingCreation">
          <img
            v-if="!loadingCreation"
            :src="require('@/assets/icons/check.svg')"
            alt=""
            class="details-form__header__icon"
          />
          Создать
        </zem-button>
      </div>
    </div>

    <div class="details-form__container">
      <validation-observer ref="registerForm">

        <div class="details-form__info-container">
          <div class="details-form__title-block">Название</div>
          <div class="details-form__data-block m-0">
            <ZemInput v-model="title" class="m-0" placeholder="Название" type="text"/>
          </div>
        </div>

        <div class="details-form__info-container">
          <div class="details-form__title-block">Статус</div>
          <div class="details-form__data-block m-0">
            <ZemDropdownList
              :default="'Статус'"
              :options="statusOptions"
              :placeholder="'Статус'"
              :value="status['title']"
              class="select mb-0"
              item="title"
              @input="status = $event"
            />
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import ZemButton from './ui/ZemButton'
import ZemInput from './ui/ZemInput'
import ZemDropdownList from './ui/ZemDropdownList'
import ZemLink from "@/components/ui/ZemLink.vue";
import EstimatesService from "@/services/estimates.service";

export default {
  components: {
    ZemLink,
    ZemButton,
    ZemInput,
    ZemDropdownList,
  },
  props: {
    currentOrder: {
      type: Object,
      default: () => ({}),
    },
    newOrder: {
      type: Boolean,
      default: false,
    },
    editOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingCreation: false,
      cancelCreation: false,
      title: '',
      status: {
        title: '',
        value: ''
      },
      statusOptions: []
    }
  },

  mounted() {
    EstimatesService.getStatusesEstimates().then(r => {
      this.statusOptions = r.data.data
    })
  },

  methods: {
    onCancelCreation() {
      this.$store.commit('sidebars/changeRightSidebar', false)
    }
  },
}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.details-form {
  border: 1px solid $color-catskill-grey;
  border-radius: 4px;
  margin: 20px;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 26px;

    &-block {
      display: flex;
      align-items: center;

      .zem-link {
        margin-right: 13px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      color: $color-mine-shaft;
      margin: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  &__title-block {
    font-weight: 400;
    font-size: 12px;
    // line-height: 140%;
    color: $color-gull-gray;
    margin: 0px 12px;
    margin-left: 0;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    // line-height: 140%;
    color: $color-mine-shaft;
    flex: 1;

    &_font_0 {
      font-size: 0;
    }

    &_margin_top {
      margin-top: 8px;
    }

    &__success {
      display: flex;
    }
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;
    margin-top: 8px;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
