<template>
    <div class="list-group-item" @click="onClickEvent">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'ZemListGroupItem',
    data() {
        return {}
    },
    methods: {
        onClickEvent() {
            this.$emit('click');
        }
    }
}
</script>

<style scoped lang="scss">
</style>
