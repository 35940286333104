<template>
    <div class="toast-created-order">
        <p class="toast-created-order__title">{{ title }}</p>
        <zem-button
            class="mt-2"
            @click="clicked"
        >
            <img
                :src="require('@/assets/icons/check.svg')"
                alt=""
                class="mr-1"
            />
            $t('Yes')
        </zem-button>
    </div>
</template>

<script>
import ZemButton from "@/components/ui/ZemButton";

export default {
    components: {
        ZemButton,
    },
    props: ['createData', 'title'],
    methods: {
        clicked() {
            this.$emit('click')
            this.$emit('close-toast')
        }
    }
};
</script>

<style lang="scss" scoped>
.toast-created-order {
    &__title {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
    }
}
</style>
